
<script lang="ts">
    export interface IProps {
        mReverseOrder?: boolean;
        title?: string | string[];
        markTitle?: boolean;
    };
</script>

<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import { useAsyncComponent } from "o365-vue-utils";
    import { isMobile } from "o365.GlobalState.ts";
    import { context } from "o365-modules";
    import ODropdown from "./Dropdown.vue";

    const MActionSheet = useAsyncComponent("o365-mobile/MActionSheet");

    const props = defineProps<IProps>();

    const titles = computed(() => (props.title instanceof Array) ? props.title : [props.title]);

    const target = ref<HTMLElement>();
    const showActionSheet = ref<boolean>(false);
    const openFn = ref<Function>();

    watch(target, () => {
        if (target.value) {
            if (isMobile.value) {
                target.value.onclick = () => {
                    showActionSheet.value = true;
                };
            } else {
                // target.value.dataset.bsToggle = "dropdown";
                target.value.onclick = () => {
                    openFn.value?.();
                };
            }
        }
    });
</script>

<template>
    <template v-if="isMobile">
        <slot name="target" :target="(el) => target = el" />
        <MActionSheet :title="title" v-model="showActionSheet" :reverse="isMobile && mReverseOrder">
            <slot />
        </MActionSheet>
    </template>
    <template v-else>
        <template v-if="true">
        
                                                    <ODropdown>
                                                        <template #default="{ target: d_target, open }">
                                                            <slot name="target" :target="(el) => { openFn = open; target = el; d_target(el); }" />
                                                        </template>
                                                        <template #dropdown="{ container, close }">
                                                            <ul class="dropdown-menu show" :ref="container">
                                                                <!-- <li><h6 class="dropdown-header">{{ $t("Select a Type") }}</h6></li> -->
                                                                <!-- <li v-for="type in 10"> -->
                                                                    <!-- <button class="dropdown-item">{{ type }}</button> -->
                                                                <!-- </li> -->
                                                                <template v-if="title">
                                                                    <li>
                                                                        <div class="dropdown-header" :class="{ 'mark': markTitle }">
                                                                            <template v-for="title in titles">
                                                                                <div>{{ title }}</div>
                                                                            </template>
                                                                        </div>
                                                                    </li>
                                                                </template>

                                                                <slot />
                                                            </ul>
                                                        </template>
                                                    </ODropdown>
        </template>
        <template v-else>
            <!-- <div class="dropdown"> -->
                <slot name="target" :target="(el) => target = el" />
                <ul class="dropdown-menu">
                    <template v-if="title">
                        <li>
                            <div class="dropdown-header" :class="{ 'mark': markTitle }">
                                <template v-for="title in titles">
                                    <div>{{ title }}</div>
                                </template>
                            </div>
                        </li>
                    </template>
                    <slot />
                </ul>
            <!-- </div> -->
        </template>
    </template>
</template>
